import logo from './logo.png';
import { CgEnter, CgMenuRight } from 'react-icons/cg';
import { CiMail } from "react-icons/ci";
import { FaPhoneAlt } from "react-icons/fa";


const Nav = () => {
    function scrollToElement(id) {
        var element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
    return (
        <>

            <nav class="navbar sticky-top navbar-expand-lg p-0" style={{ borderBottom: '2px solid white', backgroundColor: '#6B9080' }}>
                <div class="container " style={{}}>
                    <a class="navbar-brand" href="/" style={{ padding: '10px 0' }}>
                        <img src={logo} alt="" style={{ height: '60px', maxWidth: '60vw', objectFit: 'contain', }} />
                    </a>
                    <a class='navbar-toggler' data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation" style={{ border: 'none', }}>
                        <span class="" style={{ color: 'white', fontSize: '50px' }}><CgMenuRight /></span>
                    </a>

                    <div class="collapse navbar-collapse" id="navbarToggler" >
                        <div></div>
                        <ul class="navbar-nav ms-auto mt-1 mb-lg-0" style={{ fontWeight: 'bold', }} >

                            <a href='/' style={{ color: 'white', padding: '15px 15px', margin: '10px 10px', display: 'flex', justifyContent: 'center', textDecoration: 'none' }}>
                                Home
                            </a>
                            <a href='/about' style={{ color: 'white', padding: '15px 15px', margin: '10px 10px', display: 'flex', justifyContent: 'center', textDecoration: 'none' }}>
                                About
                            </a>
                            <a href='/ourTeam' style={{ color: 'white', padding: '15px 15px', margin: '10px 10px', display: 'flex', justifyContent: 'center', textDecoration: 'none' }}>
                                Our&nbsp;Team
                            </a>
                            <a href='/faq' style={{ color: 'white', padding: '15px 15px', margin: '10px 10px', display: 'flex', justifyContent: 'center', textDecoration: 'none' }}>
                                FAQs
                            </a>

                            <a style={{ display: 'flex', justifyContent: 'center', padding: '20px 0', marginTop: '-5px' }}>

                                <button className='shadow' style={{ border: 'none', backgroundColor: '#455E53', color: '#fff', padding: '5px 20px', borderRadius: '15px', width: '150px', height: '50px', fontWeight: 'bold' }}>Apply Now</button>
                            </a>


                        </ul>


                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', }}>


                        <div className='d-block' style={{ padding: '10px 10px 20px 20px', color: 'white' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', fontSize: '12.5', textAlign: 'center', paddingBottom: '5px' }}>
                                Call to speak with one of our advisors
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', fontWeight: 'bold', }}>
                                <a href='+18282755090' className='shadow' style={{ backgroundColor: 'white', padding: '5px 10px', borderRadius: '10px 20px', color: '#455E53', textDecoration: 'none' }}>
                                    <FaPhoneAlt style={{ paddingRight: '5px' }} />
                                    +1-828-275-5090

                                </a>
                            </div>

                        </div>
                    </div>
                </div >
            </nav >
        </>
    );
}

export default Nav;