import React from 'react';
import logo from '../logoNoText.png';
import { TypeAnimation } from 'react-type-animation';

const Header = () => {
    const heroTextStyle = {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '49.5%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
    };

    const videoStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: -1, // Ensure video is behind the text
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Adjust the opacity and color as needed
        zIndex: 0, // Ensure overlay is above the video but below the text
    };

    return (
        <div>
            <div style={{ height: '90vh', position: 'relative' }} className="d-flex align-items-center justify-content-center">
                <video style={videoStyle} autoPlay loop muted>
                    <source src="/headerVideo.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

                <div style={overlayStyle}></div> {/* Overlay div */}


                <div style={heroTextStyle} className="text-center">
                    <div style={{ fontSize: '30px' }}>
                        <img src={logo} alt="" style={{ height: '100px', objectFit: 'contain', marginBottom: '5px' }} />

                        <div>
                            <div style={{ fontSize: '25px', fontWeight: 'bold', }}>
                                National Business Solutions:
                            </div>
                            <div style={{ fontWeight: 'lighter', lineHeight: '30px', borderBottom: '2px solid #6B9080', paddingBottom: '15px' }}>
                                Your Partner in Unleashing Unstoppable Growth
                            </div>
                        </div>

                        <div style={{ minHeight: '220px', fontSize: '50px', lineHeight: '55px', fontWeight: 'bold', fontFamily: 'Playwrite GB J', paddingTop: '15px', display: 'flex', justifyContent: 'center' }}>
                            <TypeAnimation
                                sequence={['Stop Settling', 1000, 'Stop Settling for Less!', 500, 'Dream Bigger', 1500, 'Dream Bigger, Inspire Growth!', 2500, 'Break Limits', 2000, 'Break Limits, Build Dreams!', 3000]}
                                wrapper="div"
                                loop={Infinity}
                                style={{ width: '250px', }}
                                repeat={50}
                            />
                        </div>

                        <div style={{ fontSize: '20px', lineHeight: '40px', paddingTop: '30px', fontWeight: 'lighter' }}>
                            Secure the Capital Your Business Deserves.
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ marginBottom: '1rem', display: 'flex', fontSize: '15px', padding: '20px' }}>
                            <input
                                type="text"
                                placeholder='  How much capital is needed?'
                                style={{
                                    height: '50px',
                                    border: '1px solid #ced4da',
                                    borderRadius: '15px 0 0 15px',
                                    width: '220px',
                                    outline: 'none'
                                }}
                            />
                            <button
                                type="button"
                                style={{
                                    border: 'none',
                                    borderRadius: '0 15px 15px 0',
                                    width: '120px',
                                    background: '#455E53',
                                    color: 'white',
                                    borderLeft: 'none',
                                    fontWeight: 'bold'
                                }}
                            >
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
