import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaFileInvoiceDollar } from "react-icons/fa";
import { MdAccountBalance, MdBusinessCenter, MdOutlineBusiness } from "react-icons/md";
import { GiReceiveMoney, GiFactory } from "react-icons/gi";
import { FaDollarSign } from "react-icons/fa";
import services from '../services.png';
import cardBackground from '../cardBackground.jpg';


const Services = () => {

    const loanTypes = [
        {
            "title": "Invoice Financing",
            "icon": <FaFileInvoiceDollar />,
            "description": "Accelerate your business cash flow with our streamlined invoice financing solutions. Convert outstanding invoices into immediate working capital, unlocking funds tied up in accounts receivable. This efficient financial tool enhances liquidity, enabling businesses to operate smoothly, meet obligations promptly, and capitalize on growth opportunities without delay."
        },
        {
            "title": "SBA Loans",
            "icon": <MdAccountBalance />,
            "description": "Propel your business forward with our reliable SBA loan services, backed by competitive rates and flexible terms tailored to meet your unique financial needs. Whether expanding operations, purchasing equipment, or refinancing existing debt, our SBA loans provide the essential financial support to achieve your business goals and sustain long-term growth."
        },
        {
            "title": "Enhanced Working Capital",
            "icon": <MdBusinessCenter />,
            "description": "Access our comprehensive working capital solutions designed to optimize your business operations and facilitate strategic growth. Secure the necessary funds to effectively manage daily expenses and ensure smooth cash flow management. Our tailored financial products empower businesses to seize new opportunities and maintain operational stability with confidence."
        },
        {
            "title": "Debt Refinancing",
            "icon": <FaDollarSign />,
            "description": "Maximize your financial efficiency with our optimized debt refinancing solutions. Take advantage of lower interest rates and improved repayment terms to consolidate and refinance existing debts effectively. Our strategic refinancing options alleviate financial pressures, enhance cash flow management, and position your business for sustained growth and stability."
        },
        {
            "title": "Equipment Financing",
            "icon": <GiFactory />,
            "description": "Upgrade your business capabilities with our specialized equipment financing options. Obtain the necessary funds to purchase or lease new equipment, ensuring your operations remain at the cutting edge of industry standards. Our flexible financing solutions are designed to help you maintain productivity and competitiveness without straining your cash reserves."
        },
        {
            "title": "Business Line of Credit",
            "icon": <MdOutlineBusiness />,
            "description": "Ensure your business has access to funds whenever needed with our versatile business line of credit. This revolving credit option provides a safety net for managing unexpected expenses, seasonal fluctuations, or short-term capital needs. With easy access and flexible repayment terms, you can maintain financial agility and operational continuity."
        }
    ];

    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.1
    });

    const [offsetY, setOffsetY] = useState(0);

    const handleScroll = () => {
        setOffsetY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    const containerVariants = {
        hidden: {},
        visible: {
            transition: {
                staggerChildren: 0.5
            }
        }
    };

    const cardVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: {
            opacity: 1, x: 0, transition: {
                duration: 1.25
            }
        }
    };

    const titleVariants = {
        hidden: { x: '-100%', opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            transition: {
                duration: 1.25
            }
        }
    };

    return (
        <div style={{
            backgroundImage: `url(${cardBackground})`,
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            backgroundPosition: `center ${offsetY * 0.01}px`, // This creates the parallax effect
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh',

            padding: '50px 0',
        }} ref={ref}>
            <div className='container' style={{}}>


                <div className='' style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    <motion.div
                        initial="hidden"
                        animate={controls}
                        variants={titleVariants}
                        style={{ fontSize: '45px', fontWeight: 'bold', textAlign: 'center' }}
                    >
                        <div style={{}}>
                            <img src={services} alt="" style={{ height: '100px', objectFit: 'contain', }} />

                            <div style={{ fontSize: '60px', fontWeight: 'bold', textAlign: 'center', lineHeight: '60px', color: '' }}>
                                <span style={{ fontWeight: 'lighter', fontSize: '35px', lineHeight: '25px', fontStyle: 'italic', color: '#455E53' }}>Our</span> Working Capital Solutions
                            </div>
                        </div>
                    </motion.div>
                </div>


                <div className="" id="fundingSolutions" style={{ padding: '50px 0' }}>

                    <motion.div

                        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '25px' }}
                        variants={containerVariants}
                        initial="hidden"
                        animate={controls}
                    >

                        {
                            loanTypes.map((loan, index) => (
                                <motion.div
                                    key={index}
                                    style={{ width: '500px', backgroundColor: 'white', borderRadius: '10px', padding: '25px', }}
                                    variants={cardVariants}
                                >
                                    <div style={{ fontSize: '30px', color: '#A9C5A0' }} >{loan.icon}</div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                        <div style={{ color: '#455E53', fontWeight: 'bold', fontSize: '25px' }}>
                                            {loan.title}
                                        </div>
                                        <div>
                                            <img src={loan.icon} style={{ height: '30px' }} alt="" />
                                        </div>
                                    </div>
                                    <div>
                                        {loan.description}
                                    </div>




                                </motion.div>
                            ))
                        }
                    </motion.div>
                </div>
            </div>

        </div >
    );
}

export default Services;
