import Footer from "../Footer";
import Nav from "../Nav";
import Body from "../About/Body";
import Header from "../HomePage/Header";
import Cards from "./Cards";
import Closing from "./Closing";
import Services from "./Services";



const HomePage = () => {
    return (
        <div style={{}}>

            <Nav />

            <Header />

            <Cards />
            <Closing />
            <Services />
            <Footer />
        </div>
    );
}

export default HomePage;