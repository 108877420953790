
import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BiLineChartDown } from 'react-icons/bi'
import headerPhoto from '../hands.jpg'
import aboutUs from '../aboutUs.png'
import empowerment from '../empowerment.png'

const Header = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.5
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);



    return (
        <div style={{ display: 'flex', justifyContent: 'center' }} >
            <div className="row container align-items-center justify-content-center" style={{ padding: '25px 0' }}>

                <div className='row'>
                    <div style={{ fontSize: '60px', fontWeight: 'bold', textAlign: 'center', lineHeight: '60px', color: '' }}>
                        <span style={{ fontWeight: 'lighter', fontSize: '35px', lineHeight: '25px', fontStyle: 'italic', color: '#455E53' }}>Our Story</span> Passion, <br /> Purpose, Progress
                    </div>
                    <img src={aboutUs} alt="" style={{ height: '200px', objectFit: 'contain', paddingTop: '10px' }} />
                </div>
                <motion.div
                    className="col-sm-12 col-md-7"
                    style={{ color: 'black', marginBottom: '25px' }}
                    initial="hidden"
                    animate={controls}
                    variants={{
                        hidden: { opacity: 0, x: -100 },
                        visible: { opacity: 1, x: 0 }
                    }}
                    transition={{ duration: 1.5 }}
                    ref={ref}
                >





                    <br />
                    At <strong color='#455E53'>National Business Solutions</strong> , our journey began with a simple yet powerful idea: to empower businesses by removing financial barriers that hinder growth. Founded in the heart of California, we've always been inspired by the entrepreneurial spirit that thrives here—the daring innovators, the relentless dreamers, and the bold risk-takers who refuse to settle for mediocrity.
                    <br />
                    <br />
                    <i style={{ color: '#455E53' }}>

                        Passion fuels us. Purpose drives us. Progress defines us.
                    </i>


                </motion.div>
                <div className="col-sm-12 col-md-3 " >
                    <div style={{ color: 'black', fontSize: '30px', }}>
                        <img
                            src={empowerment}
                            className="img-fluid "
                            alt=""
                            style={{ objectFit: 'cover', }}

                        />


                    </div>

                </div>
            </div>
        </div>
    );
}

export default Header;