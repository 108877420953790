import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BiLineChartDown } from 'react-icons/bi';
import journey from '../journey.png';
import business from '../business.gif'

const Body = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.5
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#455E53', color: 'white' }} >
            <div className="row container align-items-center justify-content-center g-5" style={{ padding: '25px 0' }}>

                <div
                    className="col-sm-12 col-md-5 col-lg-4"
                    style={{ fontSize: '15px', marginBottom: '25px', }}

                >
                    <img
                        src={business}
                        className="img-fluid shadow"
                        alt=""
                        style={{ objectFit: 'cover', border: '2px white solid', borderRadius: '5px 15px' }}
                    />
                    <br /><br />
                    <div style={{
                        backgroundColor: 'white', color: '#455E53', padding: '20px', borderRadius: '20px'
                    }}>
                        <div id='body' style={{ fontSize: '25px', fontWeight: 'bold', color: '#6B9E82', fontStyle: 'italic' }}>
                            Passion
                        </div>
                        Our passion lies in witnessing businesses transform and flourish. We believe that every enterprise, regardless of size or industry, deserves the opportunity to reach its full potential. This passion wakes us up in the morning and keeps us striving long after the sun sets. It's not just about providing capital; it's about igniting possibilities and fostering success stories.                    <br /><br />
                        <div style={{ fontSize: '25px', fontWeight: 'bold', color: '#6B9E82', fontStyle: 'italic' }}>
                            Purpose
                        </div>
                        Our purpose is clear: to be the catalyst that accelerates your business growth. We exist to bridge the gap between where you are and where you want to be. By offering fast, flexible, and personalized financial solutions, we aim to eliminate the obstacles that stand in your way. Your goals become our goals, and your success becomes our mission.                    <br /><br />
                        <div style={{ fontSize: '25px', fontWeight: 'bold', color: '#6B9E82', fontStyle: 'italic' }}>
                            Progress
                        </div>
                        Progress isn't just a result; it's our ongoing commitment. We're constantly evolving, adapting, and innovating to meet the changing needs of businesses in a dynamic market. We embrace new technologies, refine our processes, and expand our services to ensure we're always a step ahead. Because when you progress, we all progress.
                    </div>
                </div>

                <div className="col-sm-12 col-md-7 col-lg-6">
                    <img
                        src={journey}
                        className="img-fluid "
                        alt=""
                        style={{ objectFit: 'cover', height: '75px' }}

                    />
                    <div style={{ fontSize: '35px', fontWeight: 'bold', }}>
                        Our Journey So Far
                    </div>
                    Since our inception, National Business Solutions has been on an inspiring journey, marked by resilience, growth, and a commitment to empowering businesses across California and beyond. Our story is not just about financial transactions; it's about the countless partnerships we've forged and the successes we've celebrated together.
                    <br /><br />
                    <div style={{ fontSize: '25px', fontWeight: 'bold', color: '#CCE3DE' }}>
                        The Beginning
                    </div>
                    Our journey began with a small, passionate team that recognized a significant gap in the market for accessible working capital. Frustrated by the slow processes and rigid requirements of traditional lenders, we set out to create a solution that prioritized speed and flexibility. Our mission was simple: to provide businesses with the funding they need to grow without unnecessary obstacles.
                    <br /><br />
                    <div style={{ fontSize: '25px', fontWeight: 'bold', color: '#CCE3DE' }}>
                        Empowering Businesses
                    </div>
                    Over the years, we have partnered with a diverse range of businesses—from bustling retail shops to innovative manufacturers—tailoring our services to fit their unique needs. We take the time to listen to our clients, understanding their challenges and aspirations. By offering personalized support and adaptable financing solutions, we've been able to help businesses turn their visions into reality and drive meaningful progress in their operations.                    <br /><br />

                    <div style={{ fontSize: '25px', fontWeight: 'bold', color: '#CCE3DE' }}>
                        Looking Ahead
                    </div>
                    As we continue to evolve, our excitement for the future grows. We remain committed to breaking down financial barriers and finding innovative ways to support businesses of all sizes. Together with our clients, we will navigate the changing landscape of business finance, ensuring they have the tools and resources to thrive.
                    <br />
                    <br />
                    <i>
                        At National Business Solutions, your success is our mission, and we can’t wait to achieve great things together.
                    </i>


                </div>
            </div>
        </div>
    );
}

export default Body;
