import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BiLineChartDown } from 'react-icons/bi';
import bodyPhoto from '../business.gif'; // Replace with a relevant image for National Business Solutions

const Body = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.5
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    return (
        <div>
            <div className='container' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

                <br /><br />
                <div style={{ fontSize: '40px', fontWeight: 'bold', textAlign: 'center' }}>
                    Frequently Asked Questions (FAQs) About Working Capital
                </div>
                <br />
                <div style={{ maxWidth: '800px', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                    At National Business Solutions, we understand that securing the right working capital is crucial for your business's growth and stability. Below are some of the most common questions we receive from our valued clients.
                </div>

            </div>
            <br />
            <hr />
            <br />
            <div style={{ display: 'flex', justifyContent: 'center', color: 'black' }} >

                <div className="row container justify-content-center" style={{ padding: '25px 0' }}>

                    <div
                        className="col-sm-12 col-md-5"
                        style={{ fontSize: '15px', marginBottom: '25px' }}

                    >


                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            What types of businesses do you work with?
                        </div>
                        <br />
                        We work with a wide range of businesses across various industries, including retail, hospitality, manufacturing, healthcare, and more. Whether you're a startup or an established company, we're here to provide the capital solutions you need.
                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            How quickly can I get funding?
                        </div>
                        <br />
                        Our approval process is streamlined for speed. Once we receive your application and necessary documentation, approvals can happen within <strong>24 to 48 hours</strong>. Funds are typically disbursed shortly after approval.
                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            What are the minimum requirements to qualify for funding?
                        </div>
                        <br />
                        While requirements may vary based on the type of financing, generally we look for:
                        <ul>
                            <li>A minimum of six months in business</li>
                            <li>Monthly revenue thresholds (varies by product)</li>
                            <li>Basic financial documentation</li>
                            <li>No severe credit issues (though we consider all applications)</li>
                        </ul>
                        <br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Do you offer financing for businesses with less-than-perfect credit?
                        </div>
                        <br />
                        Yes, we understand that credit challenges can happen. We evaluate the overall health of your business, not just your credit score. Our flexible solutions are designed to help businesses in various financial situations.
                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            What types of financing solutions do you offer?
                        </div>
                        <br />
                        We offer a variety of working capital solutions, including:
                        <ul>
                            <li><strong>Short-Term Loans</strong></li>
                            <li><strong>Business Lines of Credit</strong></li>
                            <li><strong>Equipment Financing</strong></li>
                            <li><strong>Invoice Financing</strong></li>
                            <li><strong>Merchant Cash Advances</strong></li>
                        </ul>
                        <br />

                    </div>

                    <div className="col-sm-12 col-md-5">

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            How do I apply for financing?
                        </div>
                        <br />
                        Applying is simple. You can:
                        <ul>
                            <li><strong>Fill out our online application form</strong></li>
                            <li><strong>Call us directly at (123) 456-7890</strong></li>
                            <li><strong>Email us at</strong> <a href="mailto:apply@nationalbusinesssolutions.com">apply@nationalbusinesssolutions.com</a></li>
                        </ul>
                        Our team is ready to guide you through each step.
                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Are there any upfront fees or obligations?
                        </div>
                        <br />
                        We believe in transparency. There are no hidden fees or obligations to inquire or apply. All terms and potential fees will be clearly outlined before you commit to any agreement.
                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Can I pay off my financing early?
                        </div>
                        <br />
                        Absolutely. We encourage financial responsibility and offer options for early repayment. Specific terms can vary, so please discuss this with your advisor.
                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            How does repayment work?
                        </div>
                        <br />
                        Repayment terms are tailored to fit your cash flow and business model. Options can include fixed daily, weekly, or monthly payments. We'll work with you to determine the most manageable plan.
                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            What if I have additional questions or need support?
                        </div>
                        <br />
                        We're here to help. You can reach our customer support team via:
                        <ul>
                            <li><strong>Phone:</strong> (123) 456-7890</li>
                            <li><strong>Email:</strong> <a href="mailto:support@nationalbusinesssolutions.com">support@nationalbusinesssolutions.com</a></li>
                            <li><strong>Live Chat:</strong> Click the chat icon on our website for immediate assistance.</li>
                        </ul>
                        <br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Didn't find the answer you were looking for?
                        </div>
                        <br />
                        Feel free to <strong>contact us directly</strong>. Our dedicated team is always ready to provide the information and support you need.
                        <br /><br />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Body;
