import React from 'react';

const Closing = () => {
    const heroImageStyle = {
        backgroundColor: '#455E53',
        padding: '50px 0',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
    };

    const heroTextStyle = {
        textAlign: 'center',


        color: 'white',
    };

    return (
        <div>
            <div style={heroImageStyle} className="d-flex align-items-center justify-content-center ">
                <div style={heroTextStyle} className="text-center container">
                    <div style={{ fontSize: '45px', fontWeight: 'bold' }}>
                        Ready to
                        <br />
                        <i className="lightning-effect" style={{ color: '#A9C5A0' }}>
                            Supercharge

                        </i>
                        <br />
                        Your Business Growth?

                    </div>
                    <div style={{ fontSize: '20px' }}>
                        Don't let financial constraints limit your potential. It's time to take control and propel your business to new heights.
                        <br />
                        <strong>
                            &ensp;Contact National Business Solutions today&ensp;

                        </strong>
                        and take the first step toward a prosperous future. <br /> We're here to support you

                        <strong>

                            &ensp;every step of the way.
                        </strong>





                    </div>
                    <br />
                    <a style={{ display: 'flex', justifyContent: 'center' }}>

                        <button className='shadow' style={{ border: 'none', backgroundColor: '#fff', color: '#455E53', padding: '5px 20px', borderRadius: '15px', height: '50px', fontWeight: 'bold' }}>Begin Your Application</button>                   </a>
                </div>
            </div>
        </div>
    );
}

export default Closing;
