import { FaLinkedin, FaFacebookF, FaInstagram } from 'react-icons/fa6'
import logoAlt from './logoAlt.png'

const Footer = () => {
    return (
        <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <div className='container row' style={{ margin: '25px' }}>


                <div className="col-sm-4" style={{ paddingTop: '25px', }}>

                    <div style={{ paddingLeft: '15px' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '20px' }}>
                            Address
                        </div>
                        <div >

                            <a href="https://maps.app.goo.gl/uUYTPMkdNnqSorkM6" style={{ color: '#455E53' }}>

                                7710 Balboa Ave
                                <br />
                                Suite 313-A
                                <br />
                                San Diego, CA 92111

                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3" style={{ paddingTop: '25px', }}>

                    <div style={{ paddingLeft: '15px' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '20px' }}>
                            Quick Links
                        </div>
                        <div style={{}}>

                            <a href="/" style={{ color: '#455E53', }}>Home</a>
                            <br />
                            <a href="/about" style={{ color: '#455E53' }}>About</a>
                            <br />
                            <a href="/ourteam" style={{ color: '#455E53' }}>Our Team</a>
                            <br />
                            <a href="/faq" style={{ color: '#455E53' }}>FAQs</a>

                        </div>
                    </div>
                </div>


                {/*}}
                <div className="col-sm-2" style={{ paddingLeft: '10px', fontSize: '30px', color: '#41628b', display: 'flex', gap: '10px', margin: '25px 0' }}>

                    <a href="https://www.facebook.com/profile.php?id=61560812047498" style={{ color: '#455E53', }}>
                        <FaFacebookF />
                    </a>
                    <a href="">
                        <FaInstagram />
                    </a>
                    <a href="">
                        <FaLinkedin />
                    </a>
                </div>
                */}




                <img className='image-fluid col-sm-4 pt-3' src={logoAlt} alt="" style={{ objectFit: 'contain' }} />




            </div>
            <div className='container row' style={{ margin: '25px' }}>
                <div className="col-sm-12">
                    <ol>

                        <li>
                            Annual Percentage Yield (APY) accurate as of 09/13/2024. Rate tiers are as follows: 5.00% APY applies to balances between $0.00 and $24,999.99; 5.00% APY applies to balances between $25,000.00 and $49,999.99; 5.00% APY applies to balances of $50,000.00 and $99,999.99; 5.00% APY applies to balances of $100,000.00 and $249,999.99; and 5.00% APY applies to balances of $250,000.00 and greater. Rates are variable and subject to change at any time without notice, at the sole discretion of the bank. Fees may reduce earnings.
                        </li>
                        <li>
                            National average source: FDIC as of 1/25/2024.
                        </li>
                        <li>
                            Standard data and message rates may apply to Mobile Banking services. This includes the use of NSB Inc. Mobile and other services. Android is a trademark of Google LLC. iPhone and iPad are trademarks of Apple Inc., registered in the U.S. and other countries.
                        </li>
                    </ol>
                    <br />
                    Unless otherwise specified, all advertised offers and terms and conditions of accounts and services are subject to change at any time without notice. After an account is opened or service begins, it is subject to its features, conditions and terms, which are subject to change at any time in accordance with applicable laws and agreements. <br /> Please contact an NSB Inc. representative for details.
                    <br /><br />
                    ©2024 NSB Inc., All Rights Reserved
                </div>

            </div>
        </div>
    )
}

export default Footer;