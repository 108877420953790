import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaFastForward } from "react-icons/fa";
import { MdCurrencyExchange } from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";
import { PiPercentFill } from "react-icons/pi";
import questions from '../questions.png';
import cardBackground from '../cardBackground.jpg';


const Cards = () => {

    const loanTypes = [
        {
            "title": "Fast Approvals",
            "icon": <FaFastForward />,
            "description": "When you need funding, timing is everything. Our process is built for speed, ensuring you get the financial support you need, exactly when you need it—no delays, no hassle. Your time is important, and we make sure our service reflects that."
        },
        {
            "title": "Flexible Terms",
            'icon': <MdCurrencyExchange />,
            "description": "Our financing options are designed to adapt to your business needs, not the other way around. We offer flexible solutions that align with your goals, giving you the freedom to grow and succeed on your own terms, without rigid constraints."
        },
        {
            "title": "Personalized Service",
            'icon': <RiCustomerService2Fill />,
            "description": "From start to finish, our dedicated advisors provide expert, personalized support. You’re more than just a client to us—you’re a partner. We work closely with you to ensure every financial solution is tailored to meet your specific business needs."
        },
        {
            "title": "Competitive Rates",
            'icon': <PiPercentFill />,
            "description": "Our goal is to help you keep more of what you earn. With competitive rates, we offer affordable financing that helps you maintain healthy cash flow while driving profits. We work to provide cost-effective solutions without compromising on quality."
        }
    ];

    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.1
    });

    const [offsetY, setOffsetY] = useState(0);

    const handleScroll = () => {
        setOffsetY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    const containerVariants = {
        hidden: {},
        visible: {
            transition: {
                staggerChildren: 0.5
            }
        }
    };

    const cardVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: {
            opacity: 1, x: 0, transition: {
                duration: 1.25
            }
        }
    };

    const titleVariants = {
        hidden: { x: '-100%', opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            transition: {
                duration: 1.25
            }
        }
    };

    return (
        <div style={{
            backgroundImage: `url(${cardBackground})`,
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            backgroundPosition: `center ${offsetY * 0.01}px`, // This creates the parallax effect
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh',

            padding: '50px 0',
        }} ref={ref}>
            <div className='container' style={{}}>
                <div className='row'>

                    <div className='col-12 col-md-4' style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                        <motion.div
                            initial="hidden"
                            animate={controls}
                            variants={titleVariants}
                            style={{ fontSize: '45px', fontWeight: 'bold', textAlign: 'center' }}
                        >
                            <div style={{ fontSize: '60px', fontWeight: 'bold', textAlign: 'center', lineHeight: '60px', color: '' }}>
                                <span style={{ fontWeight: 'lighter', fontSize: '35px', lineHeight: '25px', fontStyle: 'italic', color: '#455E53' }}>Why Choose</span> National Business Solutions?
                            </div>
                            <img src={questions} alt="" style={{ height: '200px', objectFit: 'contain', paddingTop: '10px' }} />
                        </motion.div>
                    </div>


                    <div className="col-12 col-md-8 " id="fundingSolutions" style={{ padding: '50px 0' }}>

                        <motion.div

                            style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '25px' }}
                            variants={containerVariants}
                            initial="hidden"
                            animate={controls}
                        >

                            {
                                loanTypes.map((loan, index) => (
                                    <motion.div
                                        key={index}
                                        style={{ width: '300px', backgroundColor: 'white', borderRadius: '10px', padding: '25px', }}
                                        variants={cardVariants}
                                    >
                                        <div style={{ fontSize: '30px', color: '#A9C5A0' }} >{loan.icon}</div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                            <div style={{ color: '#455E53', fontWeight: 'bold', fontSize: '25px' }}>
                                                {loan.title}
                                            </div>
                                            <div>
                                                <img src={loan.icon} style={{ height: '30px' }} alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            {loan.description}
                                        </div>




                                    </motion.div>
                                ))
                            }
                        </motion.div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Cards;
